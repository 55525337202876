import React, { Component } from 'react'
import {Row,Col,message} from 'antd'
import MainHeader from './mainHeader'
import Slider from './slider'
import {Switch,Route} from 'react-router-dom'
import userAdminManage from './accountManage/index'
import viewManage from './viewManage/index'
import superAdminManage from './superadminManage/index'
import setPermission from './superadminManage/setPermission/index'
import helpManage from './helpManage/index'
import systemSafeManage from './systemSafeManage/index'
import tagManage from './tagManage/index'
import guideManage from './guideManage/index'
import regionManage from './regionManage/index'
import shoppingManage from './shoppingManage/index'
import foodManage from './foodManage/index'
import hotelManage from './hotelManage/index'
import leisureManage from './leisureManage/index'
import visitManage from './visit/index'
import indexShowPage from './indexShowPageManage/index'
import recommendManage from './recommendManage/index'
import hotTripManage from './hotTripManage/index'
import showPageManage from './showPageManage/index'


class Index extends Component {
    constructor(props,context){
        super(props,context);
        this.state={
            sliderHeight:0,
            contentHeight:0,
            collapsed:false,
            colSlider:3,
            colPage:21
        }
    }
    componentDidMount() {
        let _height=window.innerHeight;
        this.setState({
            sliderHeight: _height,
            contentHeight:_height-41
        });
    }
    iscollapsedChange(val){
        if(val){
            this.setState({
                colSlider: 0,
                colPage: 24,
            });
        }else{
            this.setState({
                colSlider: 3,
                colPage: 21,
            });
        }
        this.setState({
            collapsed: val
        });
    }
    isQuit(val){
        if(val){
            window.localStorage.setItem('user_name','');
            window.localStorage.setItem('pass_word','');
            window.sessionStorage.setItem('token','');
            window.sessionStorage.setItem('menu','');
            window.sessionStorage.setItem('userName','');
            window.sessionStorage.setItem('userId','');
            window.sessionStorage.setItem('password','');
            message.success('当前账号已退出！')
            this.props.history.push('/login');
        }
    }
    render() {
        return (
            <div id="index">
                <Row>
                    <Col span={this.state.colSlider} >
                        <Slider isCollapesd={this.state.collapsed} height={this.state.sliderHeight} />
                    </Col>
                    <Col span={this.state.colPage} >
                        <Row>
                            <Col span={24} >
                                <MainHeader isQuit={this.isQuit.bind(this)} iscollapsed={this.iscollapsedChange.bind(this)}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{background:'rgba(240, 242, 245, 1)',padding:'20px',height:this.state.contentHeight}}>
                                <Switch>
                                    <Route path='/index/userAdminManage' component={userAdminManage}/>
                                    <Route path='/index/viewManage' component={viewManage}/>
                                    <Route path='/index/superAdminManage' component={superAdminManage}/>
                                    <Route path='/index/setPermission' component={setPermission}/>
                                    <Route path='/index/helpManage' component={helpManage}/>
                                    <Route path='/index/systemSafeManage' component={systemSafeManage}/>
                                    <Route path='/index/tagManage' component={tagManage}/>
                                    <Route path='/index/guideManage' component={guideManage}/>
                                    <Route path='/index/regionManage' component={regionManage}/>
                                    <Route path='/index/shoppingManage' component={shoppingManage}/>
                                    <Route path='/index/foodManage' component={foodManage}/>
                                    <Route path='/index/hotelManage' component={hotelManage}/>
                                    <Route path='/index/leisureManage' component={leisureManage}/>
                                    <Route path='/index/visitManage' component={visitManage}/>
                                    <Route path='/index/indexShowPage' component={indexShowPage}/>
                                    <Route path='/index/recommendManage' component={recommendManage}/>
                                    <Route path='/index/hotTripManage' component={hotTripManage}/>
                                    <Route path='/index/showPageManage' component={showPageManage}/>
                                    
                                </Switch>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Index;