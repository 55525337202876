import axios from 'axios';
import {message} from 'antd';
import {api} from '../common/index'

let token='';

axios.defaults.timeout = 15000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;';
axios.defaults.baseURL = api();
axios.defaults.withCredentials=false;//携带cookie
axios.defaults.headers.common['token'] = token;
axios.interceptors.request.use((config) => {
    if (window.sessionStorage.getItem('token') !== '') {
        let user = window.sessionStorage.getItem('token');
            if (user) {
                token = user;
            }
            config.headers.common['token'] = token;
    } else {
        window.router.push('/login');
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
//返回状态判断
axios.interceptors.response.use((res) => {
    return res;
}, (err) => {
    //404等问题可以在这里处理
    if (err.response) {
        switch (err.response.status) { // 判断后台返回的token值是否失效
            case 401:
                message.error('登录过期，请重新登录！');
                window.router.push('/login');
                break

            case 400:
                message.error(err.response.data.message);
                break

            case 403:
                message.error(err.response.data.message);
                break

            case 404:
                message.error(err.response.data.message);
                break

            case 408:
                message.error(err.response.data.message);
                break

            case 500:
                message.error(err.response.data.message);
                break

            case 501:
                message.error(err.response.data.message);
                break

            case 502:
                message.error(err.response.data.message);
                break

            case 503:
                message.error(err.response.data.message);
                break

            case 504:
                message.error(err.response.data.message);
                break

            case 505:
                message.error(err.response.data.message);
                break

            default:
        }
        return Promise.reject(err);
    } else if (err.request) {
        return Promise.reject(err.request);
    } else {
        return Promise.reject(err.message);
    }
});
export default {
    patchsShenhe(url,params) {
        return new Promise((resolve, reject) => {
            axios.patch(url,params)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    uploadPhoto(url, params, callback1) {//表单提交，上传图片
        var instance = axios.create({
            baseURL: api(),
            onUploadProgress: function (progressEvent) { //原生获取上传进度的事件
                if (progressEvent.lengthComputable) {
                    callback1(progressEvent);
                }
            }
        });
        instance.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        instance.defaults.headers.common['token'] = window.sessionStorage.getItem('token');
        return new Promise((resolve, reject) => {
            instance.post(url, params)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    patchsMoney(url,params){
        return new Promise((resolve, reject) => {
            axios.patch(`${url}?id=${params.id}&money=${params.money}`)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        }) 
    },
    patchsPassword(url,params){
        return new Promise((resolve, reject) => {
            axios.patch(`${url}?password=${params.password}`)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        }) 
    },
    pathPermission(url,params){
        return new Promise((resolve, reject) => {
            axios.patch(`${url}?id=${params.id}&permission=${params.permission}`)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        }) 
    },
    patchsType(url,params){
        return new Promise((resolve, reject) => {
            axios.patch(`${url}?classifyName=${params.classifyName}&id=${params.id}`)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        }) 
    },
    patchsDivide(url,params) {
        return new Promise((resolve, reject) => {
            axios.patch(`${url}?level=${params.level}&percentage=${params.percentage}`)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    patchsReject(url,params) {
        return new Promise((resolve, reject) => {
            axios.patch(`${url}?message=${params}`)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    patchsLevel(url,params) {
        return new Promise((resolve, reject) => {
            axios.patch(`${url}?level=${params}`)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    patchsPhone(url,params) {
        return new Promise((resolve, reject) => {
            axios.patch(`${url}?phone=${params}`)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    patchs(url,params) {
        return new Promise((resolve, reject) => {
            axios.patch(url,{'params':params})
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    posts(url, params) {
        return new Promise((resolve, reject) => {
            axios.post(url, params)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    delBatch(url, params){
        return new Promise((resolve, reject) => {
            axios.post(url, params)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    },
    updateParam(url, params) {//url传参
        return new Promise((resolve, reject) => {
            axios.post(`${url}?id=${params}`)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    },
    updateParamAccount(url, params) {//url传参
        return new Promise((resolve, reject) => {
            axios.post(`${url}?orderId=${params}`)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    },
    saves(url, params) {//保存方法
        return new Promise((resolve, reject) => {
            axios.post(url, params)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    update(url, params) {//修改方法
        return new Promise((resolve, reject) => {
            axios.put(url, params)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    view(url,params) {
        return new Promise((resolve, reject) => {
            axios.get(url,{'params':params})
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    gets(url,params) {
        return new Promise((resolve, reject) => {
            axios.get(url,{'params':params})
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    deletes(url,params) {
        return new Promise((resolve, reject) => {
            axios.delete(url,{'params':params})
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    },
    puts(url, params) {
        return new Promise((resolve, reject) => {
            axios.put(url, params)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    instancePosts(url, params) {//登录
        var instance = axios.create({
            baseURL:'http://apigateway.dev-hk.com'
        });
        instance.defaults.headers.post['Content-Type'] = 'multipart/form-data';

        return new Promise((resolve, reject) => {
            instance.post(url, params)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}