import {message} from "antd/lib/index"

/**
 *导航守卫
 * */
export function beforeRouterEach(_this) {
    if (window.sessionStorage.getItem('token') === '' || window.sessionStorage.getItem('token') === null) {
        message.warning('请先登录系统！');
        _this.props.history.push('/login');
    }
}

/**
 * 时间格式转换
 */
export function dateFormat(val){
    const d = new Date(val)
    const resDate = d.getFullYear() + '-' + p((d.getMonth() + 1)) + '-' + p(d.getDate())
    const resTime = p(d.getHours()) + ':' + p(d.getMinutes()) + ':' + p(d.getSeconds())
    return resDate+' '+resTime
}
export function dateFormatNoSecond(val){
    const d = new Date(val)
    const resDate = d.getFullYear() + '-' + p((d.getMonth() + 1)) + '-' + p(d.getDate())
    return resDate
}
export function p(s) {
    return s < 10 ? '0' + s : s
}
export function parserDate(date) {
    var t = Date.parse(date)
    if (!isNaN(t)) {
      return new Date(Date.parse(date.replace(/-/g, '/')))
    }
}
export function parserDateString(date){
    var s = new Date(date)
    var t = s.getTime()
    if (!isNaN(t)) {
      return new Date(Date.parse(date.replace(/-/g, '/')))
    }
}
/**
 * 深拷贝
 */
export function getType(obj){
    //tostring会返回对应不同的标签的构造函数
    var toString = Object.prototype.toString;
    var map = {
        '[object Boolean]'  : 'boolean',
        '[object Number]'   : 'number',
        '[object String]'   : 'string',
        '[object Function]' : 'function',
        '[object Array]'    : 'array',
        '[object Date]'     : 'date',
        '[object RegExp]'   : 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]'     : 'null',
        '[object Object]'   : 'object'
    };
    if(obj instanceof Element) {
        return 'element';
    }
    return map[toString.call(obj)];
}
export function deepCopy(data){
    var type = getType(data);
    var obj;
    if(type === 'array'){
        obj = [];
    } else if(type === 'object'){
        obj = {};
    } else {
        //不再具有下一层次
        return data;
    }
    if(type === 'array'){
        for(var i = 0, len = data.length; i < len; i++){
            obj.push(deepCopy(data[i]));
        }
    } else if(type === 'object'){
        for(var key in data){
            obj[key] = deepCopy(data[key]);
        }
    }
    return obj;
}

export function api(){
    //开发
    //return 'https://api.houston.kaifa.fun'
    //正式
    return 'https://api.visithouston.top'
}