import React from 'react'
import { message} from 'antd'
import axios from '../../axios/index'
import './index.css'
class UploadBox extends React.Component{
    constructor(props,context){
        super(props,context);
        this.state={
        }
    }
    uploadpic(e){
        let file=e.target.files;
        if(file[0].type==='image/jpg'||file[0].type==='image/jpeg'){
            if((file[0].size/1024)<=1024){
                let param = new FormData(); // 创建form对象
                param.append("file", file[0]);
            
                axios.uploadPhoto('/file/upload', param ,(res)=>{})
                .then(response => {
                        if(response.status===200){
                            message.success('上传成功！')
                            this.props.getImage(response.data)
                        }else{
                            message.error('上传失败！')
                        }
                    },response => {
                        message.error('上传失败！')
                })
            }else{
                message.warning('图片大小不能超过1M！')
            } 
           
        }else{
            message.warning('图片格式必须为jpg！')
        }
          
    }
    fileClick(e) {
        document.getElementsByClassName('upload_file')[0].click()
    }
    fileDel(e) {
        // $(e.target).parents('.upload_img').find('input').val('')
        this.data.pictureUrl="";
    }
    render(){
        return(
            <div className="upload_img">
               
                <div className="upload_body">
                    <div className="upload_body_center">
                        <div className="upload_addZoo"  onClick={this.fileClick.bind(this)}>+</div>
                    </div>
                </div>
     
                <input 
                name="file"
                accept="image/png, image/jpeg, image/jpg" 
                onChange={this.uploadpic.bind(this)} 
                type="file" 
                className="upload_file" 
                style={{display: 'none'}}/>
            </div>
        )
    }
}

export default UploadBox;