import React from 'react'
import {Row, Col, Input, Select, Button,Modal,Form,Upload,Icon,message,Divider,Table,Tag  } from 'antd'
import UploadBox from '../../../base/upload/index'
import AddViewInfo from '../addInfoModal/index'
import ModifyViewInfo from '../modifyInfoModal/index'
import AddHot from '../addHot/index'
import AddService from '../addService/index'
import MapAdd from '../../../base/map/index'
import {api} from '../../../common/index'
const {Option} = Select
const { TextArea } = Input

class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            addViewInfoVisible:false,
            modifyViewInfoVisible:false,
            infoColumns:[
                  {
                    title: '信息标题',
                    dataIndex: 'viewTitle',
                
                  },
                  {
                    title: '信息内容',
                    dataIndex: 'viewContent', 
                  
                  },
                  {
                    title: '操作',
                    key: 'action',
                    render: (text, record) => (
                        <span>
                            <span>
                            <span className='tableBtn' onClick={(event) => {
                                        this.modifyViewInfo(record)
                                        event.stopPropagation()
                                    }}>编辑</span><Divider type="vertical"/>
                                <span className='tableBtn' onClick={(event) => {
                                        this.delInfo(record)
                                        event.stopPropagation()
                                    }}>删除</span>
                                
                            
                            </span>
                        </span>
                    ),
                  },
            ],
            modifyInfoData:{
                infoTitle:'',
                infoContent:''
            },
            addHotVisible:false,
            addServiceVisible:false,
            hotTemp:[],
            serviceTemp:[]
        }
    }

    createAccount= (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.changeVisible(false,values);
                this.props.form.resetFields();
            }
        });
    }
    cancelCreateAccount(){
        this.props.form.resetFields();
        this.props.changeVisible(false,'');
    }
    getImage(val){
        this.props.getImage(val)
    }
    delImage(value){
        this.props.delImage(value)
    }
    delInfo(record){
        this.props.changeDelInfoVisible(record.key)
    }
    handleChangeVideo(info){
          if (info.file.status === 'done') {
            message.success(`${info.file.name} 上传成功！`);
            this.props.form.setFieldsValue({
                comment: info.file.response.data,
            });
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} 上传失败！`);
          }
    }
    addViewInfo(){
        this.setState({
            addViewInfoVisible:true
        })
    }
    changeInfoVisible(val,values){
        this.setState({
            addViewInfoVisible:val
        })
        this.props.changeInfoVisible(values)
    }
    changeModifyInfoVisible(val,values){
        this.setState({
            modifyViewInfoVisible:val
        })
        if(values!==''){
            values.key=this.state.modifyInfoData.key  
        }  
        this.props.changeModifyInfoVisible(values)
    }
    modifyViewInfo(record){
        this.setState({
            modifyInfoData:record
        },()=>{
            this.setState({
                modifyViewInfoVisible:true
            })
        })
    }
    addHot(){
        this.setState({
            addHotVisible:true
        })
    }
    addService(){
        this.setState({
            addServiceVisible:true
        })
    }
    closeTag(index){
        this.props.delTag(index)
    }
    changeHotVisible(val,values){
        this.setState({
            addHotVisible:val
        })
        if(values==='submit'){
            this.props.changeHot(this.state.hotTemp)
        }
        this.setState({
            hotTemp:[]
        })
    }
    changeServiceVisible(val,values){
        this.setState({
            addServiceVisible:val
        })
        if(values==='submit'){
            this.props.changeService(this.state.serviceTemp)
        }
        this.setState({
            serviceTemp:[]
        })
    }
    changeHot(val){
        this.setState({
            hotTemp:val
        })
    }
    changeService(val){
        this.setState({
            serviceTemp:val
        })
    }
    getlatlng(lat,lng){
        let latlng=lat+','+lng
        this.props.form.setFieldsValue({
            latlng: latlng
          });
    }   
    render() {
        const {getFieldDecorator} = this.props.form;
        const images=this.props.images
        const data=this.props.addInfoData
        const tagData=this.props.tagData
        const hotData=this.props.hotData
        const hotDataSelect=this.props.hotDataSelect
        const serviceDataSelect=this.props.serviceDataSelect
        const hotDataSelected=[]
        const serviceDataSelected=[]
        hotDataSelect.map((item,index)=>{
            hotDataSelected.push(item.value)
            return item
        })
        serviceDataSelect.map((item,index)=>{
            serviceDataSelected.push(item.value)
            return item
        })
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    style={{ top: 20 }}
                    width={850}
                    visible={this.props.visible}
                    title={"新增"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Form>
                        <Row style={{height:'550px',overflowY:'auto'}}>
                            <Col span={24}>
                                <Form.Item
                                    label="名称(中文)"
                                    labelCol={{span: 4}} wrapperCol={{span: 15}}
                                    >
                                        {getFieldDecorator('zhName', {
                                            rules: [{
                                                required: true, message: '请填写名称(中文)!',
                                            }],
                                        })(
                                            <Input style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="名称(英文)"
                                    labelCol={{span: 4}} wrapperCol={{span: 15}}
                                    >
                                        {getFieldDecorator('enName', {
                                            rules: [{
                                                required: true, message: '请填写名称(英文)!',
                                            }],
                                        })(
                                            <Input  style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="轮播图"
                                    labelCol={{span: 4}} wrapperCol={{span: 20}}
                                >
                                    {getFieldDecorator('photo', {
                                        initialValue: images.join(','),
                                        rules: [{
                                            required: true, message: '请上传图片!',
                                        }],
                                    })(
                                        <span style={{display:'none'}}></span>
                                    )}
                                    
                                    <Row>
                                        <Col span={24}>
                                        {
                                            images.map((item,index)=>{
                                                return  <div key={index} style={{width:'126px',height:'95px',display:'inline-block',marginLeft:'10px',backgroundSize:'100% 100%',backgroundImage:'url('+ item +')'}}>
                                                <div style={{cursor:'pointer',display:'inline',float:'right',lineHeight:'20px'}} onClick={this.delImage.bind(this,index)}>
                                                    <img src={[require("../../../assets/image/goodsFiles/del.png")]} alt="" />
                                                </div>
                                            
                                            </div>
                                        })
                                        }
                                        {
                                            images.length>=6?'':<UploadBox getImage={this.getImage.bind(this)}/>
                                        }
                                        </Col>
                                    </Row>
                                   
                                   <Row>
                                        <Col span={24}>
                                        <p>1. 图片宽高比要求16:9,推荐尺寸为750*360</p>
                                        <p>2. jpg格式,大小300k-1M(在保证清晰度的情况下尽可能压缩)</p>
                                        <p>3. 最少一张,最多六张</p>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col> 
                            
                            <Col span={24} >
                                <Form.Item
                                    label="音频解说"
                                    labelCol={{span: 4}} wrapperCol={{span: 15}}
                                >
                                    {getFieldDecorator('comment', {
                                        rules: [{
                                            // required: true, message: '请上传音频!',
                                        }],
                                    })(
                                        <span style={{display:'none'}}></span>
                                    )}
                                   
                                    <Upload 
                                        name="file"
                                        action={api()+"/file/upload"}
                                        onChange={this.handleChangeVideo.bind(this)}
                                    >
                                        <Button>
                                            <Icon type="upload" /> 上传音频
                                        </Button>
                                    </Upload>
                                </Form.Item>
                                
                            </Col>           
                            <Col span={24} >
                                <Form.Item
                                label="精品推荐"
                                labelCol={{span: 4}} wrapperCol={{span: 15}}
                                >
                                    {getFieldDecorator('recommend', {
                                        rules: [{
                                            required: true, message: '请选择是否精品推荐!',
                                        }],
                                    })(
                                        <Select
                                        style={{width:'50%'}}
                                        size={'small'}
                                    >
                                        <Option value="0">否</Option>
                                        <Option value="1">是</Option>
                                    </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="电话"
                                    labelCol={{span: 4}} wrapperCol={{span: 15}}
                                    >
                                        {getFieldDecorator('phone', {
                                            rules: [{
                                                // required: true, message: '请填写名称(英文)!',
                                            }],
                                        })(
                                            <Input  style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="网址"
                                    labelCol={{span: 4}} wrapperCol={{span: 15}}
                                    >
                                        {getFieldDecorator('website', {
                                            rules: [{
                                                // required: true, message: '请填写名称(英文)!',
                                            }],
                                        })(
                                            <Input  style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                            </Col>
                            <Col span={24} >
                                <Form.Item
                                label="标签"
                                labelCol={{span: 4}} wrapperCol={{span: 15}}
                                >
                                    {getFieldDecorator('label', {
                                        rules: [{
                                            required: true, message: '请选择景点标签!',
                                        }],
                                       
                                    })(
                                        <Select
                                            mode="multiple"
                                            size={'small'}
                                        >   
                                        {
                                            tagData.map((item,index)=>{
                                                return <Option key={index} value={item.id}>{item.name}</Option>
                                            })
                                        }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24} >
                                <Form.Item
                                label="介绍"
                                labelCol={{span: 4}} wrapperCol={{span: 15}}
                                >
                                    {getFieldDecorator('content', {
                                        rules: [{
                                            required: true, message: '请填写介绍!',
                                        }],
                                    })(
                                        <TextArea  rows={4} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24} >
                                <Form.Item
                                label="其他信息"
                                labelCol={{span: 4}} wrapperCol={{span: 15}}
                                >
                                    {getFieldDecorator('message', {
                                        rules: [{
                                            // required: true, message: '请填写其他信息!',
                                        }],
                                    })(
                                        <span style={{display:'none'}}></span>
                                    )}
                                    <Row>
                                        <Col span={24}>
                                            <Button type="primary" onClick={this.addViewInfo.bind(this)}>
                                                新增信息
                                            </Button>
                                        </Col>
                                        <Col span={24}>
                                            <Table pagination={false} columns={this.state.infoColumns} dataSource={data} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={24} >
                                <Form.Item
                                label="周边热门"
                                labelCol={{span: 4}} wrapperCol={{span: 15}}
                                >
                                    {getFieldDecorator('hot', {
                                        rules: [{
                                            // required: true, message: '请选择景点标签!',
                                        }],
                                        
                                    })(
                                        <span style={{display:'none'}}></span>
                                    )}
                                    <Row>
                                        <Col span={24}>
                                            <Button type="primary" onClick={this.addHot.bind(this)}>
                                                添加周边热门
                                            </Button>
                                        </Col>
                                        <Col span={24}>
                                            {
                                                hotDataSelect.map((item,index)=>{
                                                    return <Tag closable onClose={this.closeTag.bind(this,index)}>
                                                                {item.label}
                                                            </Tag>
                                                })
                                            }
                                            
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={24} >
                                <Form.Item
                                label="周边服务"
                                labelCol={{span: 4}} wrapperCol={{span: 15}}
                                >
                                    {getFieldDecorator('service', {
                                        rules: [{
                                            // required: true, message: '请选择景点标签!',
                                        }],
                                        
                                    })(
                                        <span style={{display:'none'}}></span>
                                    )}
                                    <Row>
                                        <Col span={24}>
                                            <Button type="primary" onClick={this.addService.bind(this)}>
                                                添加周边服务
                                            </Button>
                                        </Col>
                                        <Col span={24}>
                                            {
                                                serviceDataSelect.map((item,index)=>{
                                                    return <Tag closable onClose={this.closeTag.bind(this,index)}>
                                                                {item.label}
                                                            </Tag>
                                                })
                                            }
                                            
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="经纬度"
                                    labelCol={{span: 4}} wrapperCol={{span: 15}}
                                    >
                                        {getFieldDecorator('latlng', {
                                            rules: [{
                                                required: true, message: '请选择经纬度!',
                                            }],
                                            
                                        })(
                                            <Input disabled style={{width:'70%'}} size={'small'}/>
                                        )}
                                        <Row>
                                            <Col span={24}>
                                                <MapAdd
                                                    getlatlng={this.getlatlng.bind(this)}
                                                />
                                            </Col>
                                        </Row>
                                </Form.Item>
                                
                            </Col>
                        </Row>
                    </Form>
                    <AddViewInfo
                        visible={this.state.addViewInfoVisible}
                        changeVisible={this.changeInfoVisible.bind(this)}
                    />
                    <ModifyViewInfo
                        visible={this.state.modifyViewInfoVisible}
                        changeVisible={this.changeModifyInfoVisible.bind(this)}
                        detailData={this.state.modifyInfoData}
                    />
                    <AddHot
                        changeHot={this.changeHot.bind(this)}
                        hotDataSelected={hotDataSelected}
                        changeVisible={this.changeHotVisible.bind(this)}
                        data={hotData}
                        visible={this.state.addHotVisible}
                    />
                    <AddService
                        changeService={this.changeService.bind(this)}
                        hotDataSelected={serviceDataSelected}
                        changeVisible={this.changeServiceVisible.bind(this)}
                        data={hotData}
                        visible={this.state.addServiceVisible}
                    />
                </Modal>    
        )
    }
}

export default Form.create({name: 'addView'})(CreateModal);