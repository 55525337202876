import React from 'react'
import {Row, Col, Input, Button,Modal,Form,Select,message} from 'antd'
import UploadBox from '../../../base/upload/index'
import {api} from '../../../common/index'
import E from 'wangeditor'
const {Option} = Select
class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            initEditor:false,
        }
    }

    createAccount= (e) => {
        e.preventDefault();
        this.props.form.setFieldsValue({
            content: this.editor.txt.html()
          });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.changeVisible(false,values);
                this.props.form.resetFields();
                this.setState({
                    initEditor:false
                })
            }
        });
    }
    cancelCreateAccount(){
        this.props.form.resetFields();
        this.props.changeVisible(false,'');
        this.setState({
            initEditor:false
        })
    }
    
    getImage(val){
        this.props.getImage(val)
    }
    delImage(value){
        this.props.delImage(value)
    }
    componentDidUpdate(){
        setTimeout(()=>{
            this.initEditor()
        },1000)
    }
    initEditor () {
        if(!this.state.initEditor&&document.getElementById('editorModify')!==null){
            this.setState({
                initEditor:true
            },()=>{
                const editor = new E('#editorModify')
     
                this.editor = editor 
            
                editor.customConfig.zIndex = 100
                editor.customConfig.uploadImgServer = api() + '/file/upload'
                // 限制一次最多上传 1 张图片
                // editor.customConfig.uploadImgMaxLength = 1
                editor.customConfig.customUploadImg = function (files, insert) {
                // files 是 input 中选中的文件列表
                if (files[0]) {
                    const formData = new window.FormData()
                    formData.append('file', files[0], 'cover.jpg')
                    fetch(api() + '/file/upload', {
                    method: 'POST',
                    body: formData
                    }).then((res) => {
                    return res.json()
                    }).then((res) => {
                    if (res.status===200) {
                        // 上传代码返回结果之后，将图片插入到编辑器中
                        insert(api()+res.data)
                    } else {
                        console.log(res.message)
                    }
                    })
                } else {
                    message.info('请选择要上传的图片！')
                }
                }
                editor.customConfig.menus = [
                'head', // 标题
                'bold', // 粗体
                'fontSize', // 字号
                // 'fontName', // 字体
                'italic', // 斜体
                'underline', // 下划线
                'strikeThrough', // 删除线
                'foreColor', // 文字颜色
                // 'backColor', // 背景颜色
                'link', // 插入链接
                'list', // 列表
                'justify', // 对齐方式
                'quote', // 引用
                // 'emoticon', // 表情
                'image', // 插入图片
                // 'table', // 表格
                // 'video', // 插入视频
                // 'code', // 插入代码
                'undo', // 撤销
                'redo' // 重复
                ]
                editor.customConfig.lang = {
                '设置标题': 'Title',
                '字号': 'Size',
                '文字颜色': 'Color',
                '设置列表': 'List',
                '有序列表': '',
                '无序列表': '',
                '对齐方式': 'Align',
                '靠左': '',
                '居中': '',
                '靠右': '',
                '正文': 'p',
                '链接文字': 'link text',
                '链接': 'link',
                '上传图片': 'Upload',
                '网络图片': 'Web',
                '图片link': 'image url',
                '插入视频': 'Video',
                '格式如': 'format',
                '上传': 'Upload',
                '创建': 'init'
                }
                editor.create()
                this.editor.txt.html(this.props.detailData.content)
            })
        }
        
      
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const typedata=this.props.typedata;
        const detailData=this.props.detailData;
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    style={{ top: 20 }}
                    width={1000}
                    visible={this.props.visible}
                    title={"编辑指南"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Form>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="指南名称"
                                    labelCol={{span: 4}} wrapperCol={{span: 19}}
                                    >
                                        {getFieldDecorator('name', {
                                            initialValue: detailData.name,
                                            rules: [{
                                                required: true, message: '请填写指南名称',
                                            }],
                                        })(
                                            <Input style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="指南展示图"
                                    labelCol={{span: 4}} wrapperCol={{span: 20}}
                                >
                                    {getFieldDecorator('photo', {
                                        initialValue: detailData.photo.join(','),
                                        rules: [{
                                            //required: true, message: '请填写房屋介绍!',
                                        }],
                                    })(
                                        <span style={{display:'none'}}></span>
                                    )}
                                    
                                    <Row>
                                        <Col span={24}>
                                        {
                                            detailData.photo.map((item,index)=>{
                                                return  <div key={index} style={{width:'126px',height:'95px',display:'inline-block',marginLeft:'10px',backgroundSize:'100% 100%',backgroundImage:'url('+ item +')'}}>
                                                <div style={{cursor:'pointer',display:'inline',float:'right',lineHeight:'20px'}} onClick={this.delImage.bind(this,index)}>
                                                    <img src={[require("../../../assets/image/goodsFiles/del.png")]} alt="" />
                                                </div>
                                            
                                            </div>
                                            })
                                        }
                                        {
                                            detailData.photo.length>0?'': <UploadBox getImage={this.getImage.bind(this)}/>
                                        }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                        <p>1. 图片宽高比要求16:9,推荐尺寸为750*360</p>
                                        <p>2. jpg格式,大小300k-1M(在保证清晰度的情况下尽可能压缩)</p>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col> 
                            <Col span={24} >
                                <Form.Item
                                label="指南类型"
                                labelCol={{span: 4}} wrapperCol={{span: 6}}
                                >
                                    {getFieldDecorator('labelId', {
                                        initialValue: detailData.labelId,
                                        rules: [{
                                            required: true, message: '请选择指南类型!',
                                        }],
                                    })(
                                        <Select
                                        size={'small'}
                                    >
                                       {
                                            typedata.map((item,index)=>{
                                                return <Option key={index} value={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24} >
                                <Form.Item
                                label="指南推荐"
                                labelCol={{span: 4}} wrapperCol={{span:6}}
                                >
                                    {getFieldDecorator('recommend', {
                                        initialValue: detailData.recommend,
                                        rules: [{
                                            required: true, message: '请选择指南推荐!',
                                        }],
                                    })(
                                        <Select
                                        size={'small'}
                                    >
                                        <Option value="0">否</Option>
                                        <Option value="1">是</Option>
                                    </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                            <Form.Item
                                label="指南内容"
                                labelCol={{span: 4}} wrapperCol={{span:19}}
                                >
                                    {getFieldDecorator('content', {
                                        initialValue: detailData.content,
                                        rules: [{
                                            required: true, message: '请填写指南内容!',
                                        }],
                                    })(
                                       <span style={{display:'none'}}></span>
                                    )}
                                    <div id='editorModify' ref='editorElemModify' style={{ height:'360px' }} />
                                </Form.Item>
                               
                            </Col>
                        </Row>
                    </Form>
                    
                </Modal>    
        )
    }
}

export default Form.create({name: 'modifyGuide'})(CreateModal);