import React, { Component } from 'react';
import { beforeRouterEach } from '../../common/index'
import axios from '../../axios'
import TableGroup from '../../base/table/index'
import Title from '../../base/title/index'
import ButtonGroup from '../../base/buttonGroup/index'
import AddModal from './addModal/index'
import ModifyModal from './modifyModal/index'
import './index.css'
import { Divider, message, Modal, Tabs, Icon, Button } from 'antd';
const { TabPane } = Tabs;
const { confirm } = Modal;

class Index extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            btnGroup: [
                {
                    type: 'add',
                    text: '新增'
                },
                {
                    type: 'del',
                    text: '删除'
                }
            ],
            titleMsg: {
                titleName: '标签分类管理',
            },
            table: {
                hasHeight: true,
                isPageShow: true,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title: '排序',
                        dataIndex: 'column',
                        render: (text, record, index) => (
                            <span>
                                <span className='tableBtn' onClick={(event) => {
                                    this.toTop(record)
                                    event.stopPropagation()
                                }}><Button type="link">置顶</Button></span><Divider type="vertical" />
                                <span className='tableBtn' onClick={(event) => {
                                    this.seqUp(record, index)
                                    event.stopPropagation()
                                }}><Icon type="arrow-up" /></span><Divider type="vertical" />
                                <span className='tableBtn' onClick={(event) => {
                                    this.seqDown(record, index)
                                    event.stopPropagation()
                                }}><Icon type="arrow-down" /></span>
                            </span>
                        ),
                    },
                    {
                        title: '标签名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                            <span>
                                <span>
                                    <span className='tableBtn' onClick={(event) => {
                                        this.modify(record)
                                        event.stopPropagation()
                                    }}>编辑</span><Divider type="vertical" />
                                    <span className='tableBtn' onClick={(event) => {
                                        this.del(record)
                                        event.stopPropagation()
                                    }}>删除</span>


                                </span>
                            </span>
                        ),
                    }
                ],
                data: [],
                isSelection: false
            },
            tableParams: {
                rows: 10,
                page: 1,
                type: 'SCENIC_SPOT'
            },
            addModalVisible: false,
            modifyModalVisible: false,

            detailData: {

            },
            selected: []

        }
    }
    toTop(record) {
        axios.gets('/api/v1/label/sticky', { id: record.id })
            .then((res) => {
                if (res.status === 200) {
                    message.success('置顶成功！')
                    this.getDataList()
                } else {
                    message.error(res.message)
                }
            })
    }
    seqDown(record, index) {
        let length = index + 1;
        if (length < this.state.table.data.length) {
            let sort1 = this.state.table.data[length].sort;
            let sort2 = record.sort;
            record.sort = sort1;
            let downRow = this.state.table.data[length];
            downRow.sort = sort2;
            axios.posts('/api/v1/label/modify', record)
                .then((res) => {
                    if (res.status === 200) {
                        axios.posts('/api/v1/label/modify', downRow)
                            .then((res) => {
                                if (res.status === 200) {
                                    message.success('操作成功！')
                                    this.getDataList()
                                } else {
                                    message.error(res.message)
                                }
                            })
                    } else {
                        message.error(res.message)
                    }
                })
        }

    }
    seqUp(record, index) {
        if (index > 0) {
            let sort1 = this.state.table.data[index - 1].sort;
            let sort2 = record.sort;
            record.sort = sort1;
            let upRow = this.state.table.data[index - 1];
            upRow.sort = sort2;
            axios.posts('/api/v1/label/modify', record)
                .then((res) => {
                    if (res.status === 200) {
                        axios.posts('/api/v1/label/modify', upRow)
                            .then((res) => {
                                if (res.status === 200) {
                                    message.success('操作成功！')
                                    this.getDataList()
                                } else {
                                    message.error(res.message)
                                }
                            })
                    } else {
                        message.error(res.message)
                    }
                })
        }
    }
    whickBtnClicked(val) {
        if (val === 'add') {
            this.setState({
                addModalVisible: true
            })
        } else if (val === 'del') {
            let _this = this;
            let ids = _this.state.selected;
            confirm({
                title: '是否删除当前选中标签?',
                content: '',
                onOk() {
                    axios.posts('/api/v1/label/remove', { ids: ids })
                        .then((res) => {
                            if (res.status === 200) {
                                message.success('删除成功！')
                                _this.getDataList()
                                _this.setState({
                                    selected: []
                                })
                            } else {
                                message.error(res.message)
                            }
                        })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    }
    modify(record) {
        this.setState({
            detailData: record
        }, () => {
            this.setState({
                modifyModalVisible: true
            })
        })
    }
    del(record) {
        let _this = this;
        confirm({
            title: '是否删除当前标签?',
            content: '',
            onOk() {
                axios.posts('/api/v1/label/remove', { ids: [record.id] })
                    .then((res) => {
                        if (res.status === 200) {
                            message.success('删除成功！')
                            _this.getDataList()
                        } else {
                            message.error(res.message)
                        }
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    getDataList() {
        axios.gets('/api/v1/label/find', this.state.tableParams)
            .then((res) => {
                if (res.status === 200) {
                    let data = Object.assign({}, this.state.table, {
                        data: res.data.records,
                        totalCount: res.data.total,
                    });
                    this.setState({
                        table: data
                    })
                } else {
                    message.error(res.message)
                }
            })
    }

    selectedChange(val) {
        this.setState({
            selected: val
        })
    }
    tableSizeChange(current, pageSize) {
        let data = Object.assign({}, this.state.table, {
            current: current,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: pageSize,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });

    }
    tablePageChange(page) {
        let data = Object.assign({}, this.state.table, {
            current: page,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            page: page,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }

    componentDidMount() {
        beforeRouterEach(this)
        this.getDataList();
    }
    changeAddModal(val, values) {
        this.setState({
            addModalVisible: val
        })
        if (values !== '') {
            let data = {
                name: values.name,
                type: this.state.tableParams.type
            }
            axios.posts('/api/v1/label/insert', data)
                .then((res) => {
                    if (res.status === 200) {
                        message.success('新增成功！')
                        this.getDataList();
                    } else {
                        message.error(res.message)
                    }
                })
        }
    }
    changeModifyModal(val, values) {
        this.setState({
            modifyModalVisible: val
        })
        if (values !== '') {
            let data = {
                id: this.state.detailData.id,
                name: values.name,
            }
            axios.posts('/api/v1/label/modify', data)
                .then((res) => {
                    if (res.status === 200) {
                        message.success('修改成功！')
                        this.getDataList();
                    } else {
                        message.error(res.message)
                    }
                })
        }
    }

    callback(key) {
        let type = '';
        if (key === '1') {
            type = 'SCENIC_SPOT'
        } else if (key === '2') {
            type = 'SHOPPING'
        } else if (key === '3') {
            type = 'DELICIOUS_FOOD'
        } else if (key === '4') {
            type = 'HOTEL'
        } else if (key === '5') {
            type = 'LEISURE_TIME'
        } else if (key === '6') {
            type = 'VISIT'
        } else if (key === '7') {
            type = 'GUIDE'
        } else if (key === '8') {
            type = 'REGION'
        }
        let data2 = Object.assign({}, this.state.tableParams, {
            page: 1,
            rows: 10,
            type: type
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }

    render() {
        return (
            <div className="tagManage">
                <Title titleMsg={this.state.titleMsg} />
                <ButtonGroup
                    whichClick={this.whickBtnClicked.bind(this)}
                    btnGroup={this.state.btnGroup}
                />

                <Tabs defaultActiveKey="1" style={{ margin: '0 20px' }} onChange={this.callback.bind(this)} type="card">
                    <TabPane tab="景点标签" key="1">

                    </TabPane>
                    <TabPane tab="购物标签" key="2">

                    </TabPane>
                    <TabPane tab="美食标签" key="3">

                    </TabPane>
                    <TabPane tab="酒店标签" key="4">

                    </TabPane>
                    <TabPane tab="休闲标签" key="5">

                    </TabPane>
                    <TabPane tab="游览分类" key="6">

                    </TabPane>
                    <TabPane tab="指南分类" key="7">

                    </TabPane>
                    <TabPane tab="区域分类" key="8">

                    </TabPane>
                </Tabs>

                <TableGroup
                    selectedChange={this.selectedChange.bind(this)}
                    pageChange={this.tablePageChange.bind(this)}
                    sizeChange={this.tableSizeChange.bind(this)}
                    table={this.state.table} />
                <AddModal
                    changeVisible={this.changeAddModal.bind(this)}
                    visible={this.state.addModalVisible}
                />
                <ModifyModal
                    detailData={this.state.detailData}
                    changeVisible={this.changeModifyModal.bind(this)}
                    visible={this.state.modifyModalVisible}
                />

            </div>
        );
    }
}

export default Index;