import React, { Component } from 'react';
import axios from '../../axios'
import TableGroup from '../../base/table/index'
import Title from '../../base/title/index'
import ButtonGroup from '../../base/buttonGroup/index'
import AddModal from '../indexShowPageManage/addModal/index'
import ModifyModal from '../indexShowPageManage/modifyModal/index'
import {beforeRouterEach,api} from '../../common/index'
import { message,Modal,Divider } from 'antd';
const { confirm } = Modal;

class Index extends Component {
    constructor(props,context){
        super(props,context);
        this.state={
            btnGroup: [
                {
                    type:'add',
                    text:'新增'
                }
                
            ],
            titleMsg: {
                titleName: '展示图设置',
            },
            table: {
                hasHeight: true,
                isPageShow: true,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title: '图片',
                        dataIndex: 'photo',
                        render: (text, record) => (
                            <span>
                               <img style={{width:'66px',height:'50px'}} src={record.photo} alt=''/>
                        </span>
                        ),
                    },   
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                            <span>
                                <span>
                                   <span className='tableBtn' onClick={(event) => {
                                            this.modify(record)
                                            event.stopPropagation()
                                        }}>编辑</span><Divider type="vertical"/>
                                    <span className='tableBtn' onClick={(event) => {
                                            this.del(record)
                                            event.stopPropagation()
                                        }}>删除</span>
                                    
                                   
                                </span>
                        </span>
                        ),
                    }
                ],
                data: [],
                isSelection: false
            },
            tableParams:{
                rows:10,
                page:1,
            },
            addModalVisible:false,
            modifyModalVisible:false,
            detailData:{
                
            },
            images:[]
        }
    }
    whickBtnClicked(val){
        if(val==='add'){
            this.setState({
                addModalVisible:true
            })
        }
    } 
    
    modify(record){
        this.setState({
            detailData:record,
            images:record.photo.split(',')
        },()=>{
            this.setState({
                modifyModalVisible:true
            })
        }) 
    }
    del(record){
        let _this=this;
        confirm({
            title: '是否删除当前图片?',
            content: '',
            onOk() {
              axios.posts('/api/v1/display/map/remove',{id:record.id})
                .then((res)=>{
                    if(res.status===200){
                        message.success('删除成功！')
                        _this.getDataList()
                    }else{
                        message.error(res.message)
                    }
                })
            },
            onCancel() {
              console.log('Cancel');
            },
        });
    }
    getDataList(){
        axios.gets('/api/v1/display/map/find',this.state.tableParams)
            .then((res)=>{
                if(res.status===200){
                    let data = Object.assign({}, this.state.table, {
                        data: res.data.records,
                        totalCount: res.data.total,
                    });
                    this.setState({
                        table: data
                    })  
                }else{
                    message.error(res.message)
                }
            })
        
        
    }
   
    selectedChange(val){
        console.log(val)
    }
    tableSizeChange(current, pageSize) {
        let data = Object.assign({}, this.state.table, {
            current: current,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: pageSize,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });

    }
    tablePageChange(page) {
        let data = Object.assign({}, this.state.table, {
            current: page,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            page: page ,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }
   
    componentDidMount(){
        beforeRouterEach(this)
        this.getDataList();
    }
    changeAddModal(val,values){
        this.setState({
            addModalVisible:val
        })
        if(values!==''){
            values.photo=values.logo
            axios.posts('/api/v1/display/map/insert',values)
                .then((res)=>{
                    if(res.status===200){
                        message.success('新增成功！')
                        this.getDataList();
                    }else{
                        message.error(res.message)
                    }
                })
        }
        this.setState({
            images:[]
        })
    }
    changeModifyModal(val,values){
        this.setState({
            modifyModalVisible:val
        })
        if(values!==''){
            let data={
                id:this.state.detailData.id,
                photo:values.logo
            }
            axios.posts('/api/v1/display/map/modify',data)
                .then((res)=>{
                    if(res.status===200){
                        message.success('修改成功！')
                        this.getDataList();
                    }else{
                        message.error(res.message)
                    }
                })
        }
        this.setState({
            images:[]
        })
    }
    
    getImage(val){
        let data=this.state.images;
        data.push(api()+val)
        this.setState({
            images:data
        })
    }
    delImage(val){
        let data=this.state.images;
        data = data.filter(function(item,index) {
            return index !== val
        });
        
        this.setState({
            images:data
        })
    }
    

    render() {
        return (
            <div className="superadminManage">
                <Title titleMsg={this.state.titleMsg}/> 
               
                <ButtonGroup
                        whichClick={this.whickBtnClicked.bind(this)}
                        btnGroup={this.state.btnGroup}
                />
             
               
               <TableGroup
                    selectedChange={this.selectedChange.bind(this)}
                    pageChange={this.tablePageChange.bind(this)}
                    sizeChange={this.tableSizeChange.bind(this)}
                    table={this.state.table}/>
                <AddModal
                     images={this.state.images}
                     getImage={this.getImage.bind(this)}
                     delImage={this.delImage.bind(this)}
                     changeVisible={this.changeAddModal.bind(this)}
                     visible={this.state.addModalVisible}
                />
                <ModifyModal
                    images={this.state.images}
                    getImage={this.getImage.bind(this)}
                    delImage={this.delImage.bind(this)}
                    changeVisible={this.changeModifyModal.bind(this)}
                    visible={this.state.modifyModalVisible}
                />
            </div>
        );
    }
}

export default Index;